.spacing{
    margin-top: 30vh;
}
.spacingforproject{
  margin-top: 20vh;
}

@media (max-width: 768px) {
  .spacing.responsive {
    margin-top: 10vh; /* Adjust the width for smaller screens */
   
  }
}

@media (max-width: 480px) {
  .spacing.responsive {
    margin-top: 20vh; 
  }
}

.spacingTop{
  margin-top: 0vh;
}

@media (max-width: 768px) {
.spacingTop.responsiveTop {
  margin-top: 15vh; /* Adjust the width for smaller screens */
 
}
}


/* styles.css */
img.responsive {
    transform: translateZ(50px);
    width: 55vh;
    margin-bottom: 5px;
  }
  
  @media (max-width: 768px) {
    img.responsive {
      width: 48vh; /* Adjust the width for smaller screens */
     
    }
  }
  
  @media (max-width: 480px) {
    img.responsive {
      width: 35vh; 
    }
  }


  .imgP{
    transform: translateZ(50px);
    width: 65vh;
    height: 45vh;
    margin-bottom: 5px;
    border-radius: 2vh;
  }
  
  @media (max-width: 768px) {
    .imgP.responsiveP {
      width: 40vh; /* Adjust the width for smaller screens */
     height: 30vh;
    }
  }
  
  
  