
 /* layout for the mobile version */

.my-custom-overlay {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7); /* Equivalent to 'bg-black/70' */
}

.my-custom-component {
    position: fixed;
    left: 0;
    top: 0;
    width: 75%; /* Equivalent to 'w-[75%]' */
    height: 100vh; /* Equivalent to 'h-screen' */
    background-color: #ecf0f3; /* Equivalent to 'bg-[#ecf0f3]' */
    padding: 10px; /* Equivalent to 'p-10' */
    transition: ease-in 0.5s; /* Equivalent to 'ease-in duration-500' */
  }
  
  @media (min-width: 640px) { /* sm breakpoint */
    .my-custom-component {
      width: 45%; /* Equivalent to 'sm:w-[45]' */
    }
  }
  
  @media (min-width: 768px) { /* md breakpoint */
    .my-custom-component {
      width: 45%; /* Equivalent to 'md:w-[45%]' */
    }
  }

  .my-slide-in-component {
    position: fixed;
    left: -100%; /* Equivalent to 'left-[-100%]' */
    top: 0;
    padding: 10px; /* Equivalent to 'p-10' */
    transition: ease-in 0.6s; /* Equivalent to 'ease-in duration-500' */
  }

  .my-rounded-shadow-button {
    border-radius: 9999px; /* Equivalent to 'rounded-full' */
    box-shadow: 0 4px 6px rgba(128, 128, 128, 0.4); /* Equivalent to 'shadow-lg shadow-gray-400' */
    padding: 12px; /* Equivalent to 'p-3' */
    cursor: pointer; /* Equivalent to 'cursor-pointer' */
  }
 
  .visible {
    display: block; /* or flex/grid/inline-block depending on your layout */
  }
  /* Breakpoint for hiding*/

  .hidden-md {
    display: block; /* Default for small screens */
    display: flex;
    justify-content: end;
    padding: 20px;
  }
  
  @media (min-width: 768px) { /* md breakpoint */
    .hidden-md {
      display: none; /* Hide on medium screens and up */
    }
  }

  .hidden-sm {
    display: none; /* Default for small screens */
  }
  
  @media (min-width: 768px) { /* md breakpoint and up */
    .hidden-sm {
      display: block; /* Show on medium screens and up */
    }
  }
.my-resumeButton-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7); /* Equivalent to 'bg-black/70' */
}

.my-resumeButton-component {
    position: fixed;
    left: 0;
    top: 0;
    width: 75%; /* Equivalent to 'w-[75%]' */
    height: 100vh; /* Equivalent to 'h-screen' */
    background-color: #ecf0f3; /* Equivalent to 'bg-[#ecf0f3]' */
    padding: 10px; /* Equivalent to 'p-10' */
    transition: ease-in 0.5s; /* Equivalent to 'ease-in duration-500' */
  }
  
  @media (min-width: 640px) { /* sm breakpoint */
    .my-resumeButton-component {
      width: 45%; /* Equivalent to 'sm:w-[45]' */
    }
  }
  
  @media (min-width: 768px) { /* md breakpoint */
    .my-resumeButton-component {
      width: 45%; /* Equivalent to 'md:w-[45%]' */
    }
  }

  .my-slide-in-component {
    position: fixed;
    left: -100%; /* Equivalent to 'left-[-100%]' */
    top: 0;
    padding: 10px; /* Equivalent to 'p-10' */
    transition: ease-in 0.5s; /* Equivalent to 'ease-in duration-500' */
  }
  .my-rounded-shadow-button {
    border-radius: 9999px; /* Equivalent to 'rounded-full' */
    box-shadow: 0 4px 6px rgba(128, 128, 128, 0.4); /* Equivalent to 'shadow-lg shadow-gray-400' */
    padding: 12px; /* Equivalent to 'p-3' */
    cursor: pointer; /* Equivalent to 'cursor-pointer' */
  }
 
  .visible {
    display: block; /* or flex/grid/inline-block depending on your layout */
  }
  /* Breakpoint for hiding*/

  .hidden-md {
    display: block; /* Default for small screens */
    display: flex;
    justify-content: end;
    padding: 20px;
  }
  
  @media (min-width: 768px) { /* md breakpoint */
    .hidden-md {
      display: none; /* Hide on medium screens and up */
    }
  }

  .hidden-sm {
    display: none; /* Default for small screens */
  }
  
  @media (min-width: 768px) { /* md breakpoint and up */
    .hidden-sm {
      display: block; /* Show on medium screens and up */
    }
  }


  /*Button Hover classes */
  .no-underline {
    text-decoration: none;
    color: inherit;
  }
  .resumeButton-class-position{
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .resumeButton-class {
    position: relative;
    top: -0.25rem; /* -top-1 */
    left: -0.25rem; /* -left-1 */
    background-color: #1f2937; /* bg-gray-800 */
    padding-top: 0.625rem; /* py-2.5 */
    padding-bottom: 0.625rem; /* py-2.5 */
    padding-left: 1.25rem; /* px-5 */
    padding-right: 1.25rem; /* px-5 */
    font-weight: 500; /* font-medium */
    text-transform: uppercase; /* uppercase */
    color: #ffffff; /* text-white */
    transition: all 0.2s ease; /* transition-all */
    
  }

  .resumeButton-class::before {
    content: '';
    position: absolute; /* before:absolute */
    top: 0.25rem; /* before:top-1 */
    left: 0.25rem; /* before:left-1 */
    z-index: -1; /* before:-z-[1] */
    height: 100%; /* before:h-full */
    width: 100%; /* before:w-full */
    border: 2px solid #374151; /* before:border-2 before:border-gray-700 */
    transition: all 0.2s ease; /* before:transition-all */
  }
  
  
  .resumeButton-class:hover {
    top: 0; /* hover:top-0 */
    left: 0; /* hover:left-0 */
  }
  
  .resumeButton-class:hover::before {
    top: 0; /* before:hover:top-0 */
    left: 0; /* before:hover:left-0 */
  }


